import React from 'react';

import Layout from '../../components/Layout';
import pic1 from '../../assets/images/services/support-it.png';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


const ITSupportServices = () => (
  <Layout>
    <div id="main box">
      <div className="inner box">
        <h1>IT Support Services</h1>
          <span className="image main">
            <img src={pic1} alt="" />
          </span>
          <article className="box">
          <p><br />
          As your business grows, so do your IT needs. From user management, technical support and 
          systems administration to having an IT security expert always monitoring your network and 
          systems, your IT support services can become unmanageable. 
          Business Solutions With Integrity offers a range of managed IT support services
          </p>
        <Row><Col>
        <Card>
          <Card.Header>Software Application Development</Card.Header>
            <Card.Body>
              <p>
              We develop Beautiful and functional websites, inventory management , products, portals and web applications 
              built on latest technologies and frameworks, delivered without cost and budget overruns
              Mature, sophisticated and well structured programming language, which allows us to produce best-in-class 
              enterprise-grade websites and web applications that are secure, robust, fast and effective.
              We use modern patterns and practices in development keeping scalability and performance and universal standards in mind.
              </p>
            </Card.Body>
          </Card></Col><Col>
          <Card>
          <Card.Header>Mobile App Development</Card.Header>
            <Card.Body>
              <p>
              We offer a unique expertise in Mobile Application Development for various platforms including 
              Android, iPad, iPhone and iPod to today's rapidly expanding global market. 
              Our diversified experience and a strong penchant for innovation and quality within our 
              Mobile Application Development help customers stay ahead of competitors, maintain superior 
              mobility and eliminate obstacles in the path of the development process while saving both time and money. 
              We believe that results come from skilled teams, not armies of people work. 
              With us, you can let your imagination work and we will ensure that our experienced 
              executive team with considerable experience in mobile makes it a reality. 
              Thanks to our Mobile Application Development Team who keep track of the latest trends
              in the mobile market and have developed apps in all the above platforms making our firm reach every handheld user.
              </p>
            </Card.Body>
          </Card></Col><Col>
          <Card>
          <Card.Header>Software Procurement Services</Card.Header>
            <Card.Body>
              <p>
              Custom software development services are not appropriate for every scenario. In case you need 
              an off-the-shelf program or application (to complement your custom software or to replace it), we can help there as well.
              Our software procurement services leverage tier-one partnerships with the IT industry’s top vendors, 
              including Microsoft, VMWare and others, to access the latest tools and services.
              We will ensure that any SaaS (Software-as-a-Solution) you procure both meets your needs and is 
              fully supported in terms of updates, configuration and security. Our team will also help you secure the best 
              possible service-level agreements (SLA) as well.
              </p>
            </Card.Body>
          </Card>
          </Col></Row>
          </ article>
      </div>
    </div>
  </Layout>
);

export default ITSupportServices;